.xio-newsletter {
	@include xio-container();
	margin-top: $baseSmallSize;

	@include media($baseLayoutBreakpoint) {
		margin-top: $baseLargeSize;
	}

	&__form {
	}

	&__formContent {
		margin-top: $baseGutter;
		@include media($baseLayoutBreakpoint) {
			margin-top: $baseSmallSize;
			@include dynamicColumns($baseGutter);
		}
	}

	&__thankyou {
		display: none;
	}

	&__thankyouIcon {
		display: none;
		position: relative;
		height: $formBaseSize;
		width: $formBaseSize;

		svg {
			height: $formBaseSize;
			width: $formBaseSize;
		}
	}

	&__thankyouIconSvg {
		display: block;
		@include center(both);
		height: $iconSizeSmall;
		width: $iconSizeSmall;

		svg {
			width: $iconSizeSmall;
			height: $iconSizeSmall;
		}
	}

	&__thankyouText {
		@include xio-font(h1);
		margin-top: $baseSmallSize;
		font-family: $fontFamilyLight;
	}

	&__fieldContainer {
		width: 100%;
		display: none;
		margin-bottom: $baseGutter;

		@include media($baseLayoutBreakpoint) {
			width: dynamicColumnSize(4, 12, $baseGutter);
		}

		&.xio-js-active {
			display: inline-block;
		}
	}

	&__info {
		margin-top: $baseGutter;
		color: $colorBaseNotActice;
		max-width: $maxTextWidth;

		@include media($baseLayoutBreakpoint) {
			width: dynamicColumnSize(4, 12, $baseGutter);
		}
	}

	&__infoLink {
		@include xio-link;
	}
}
