.xio-mainVideo {
	@include xio-container();
	margin-top: 0;
	margin-top: $baseLargeSize;
	position: relative;

	&__video {
		position: relative;
		margin-top: $baseSmallSize;
		margin-bottom: $baseSmallSize;
		width: 100%;
		height: auto;
		padding-bottom: 56.25%;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
