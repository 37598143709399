.xio-headline {
	&__head {
		@include xio-font(h2);
		position: relative;

		.xio-headline--sub & {
			@include xio-font(h3);
			font-family: $fontFamilyLight;
		}

		&::before {
			background-color: $colorActiveBackground;
			content: "";
			height: 1px;
			left: -$base;
			bottom: $base033;
			margin-left: -1000px;
			position: absolute;
			width: 1000px;
			@include media($baseLayoutBreakpoint) {
				left: -$base4;
				bottom: $base;
			}

			.xio-headline--sub & {
				display: none;
			}
		}
	}
}
