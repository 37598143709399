
.xio-graphAnimation {
	@include xio-container();
	position: relative;

	margin-top: $baseSmallMargin;
	@include media($baseLayoutBreakpoint) {
		margin-top: $baseSmallMargin;
	}

	&__animContent {
		width: 100%;
		position: relative;
		@include media($baseLayoutBreakpoint) {
			padding-left: $baseSmallMargin;
			padding-right: $baseSmallMargin;
		}
	}


	&__background {
		&--big {
			display: none;
			@include media($baseLayoutBreakpoint) {
				display: block;
			}
		}

		&--small {
			display: block;
			@include media($baseLayoutBreakpoint) {
				display: none;
			}
		}
	}

	&__backgroundImg {
		width: 100%;
	}

	&__canvas {
		position: absolute;
		left: 0;
		top: 0;
		@include media($baseLayoutBreakpoint) {
			margin-left: $baseSmallMargin;
			margin-right: $baseSmallMargin;
		}
	}

	&__tagline {
		@include xio-font(h1);
		font-family: $fontFamilyLight;
		left: $alignLeftHeaderToGrid;
		max-width: $maxTaglineWidth;
		position: absolute;
		top: $footerTaglineYPosition;
	}
}
