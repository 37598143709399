.xio-footer {
	@include xio-container();
	position: relative;

	margin-bottom: $baseSmallMargin;
	@include media($baseLayoutBreakpoint) {
		margin-bottom: $baseLargeMargin;
	}

	&__nav {
		width: 100%;
		margin-top: $base2;
	}

	&__navItem {
		display: inline-block;
		margin-right: $base2;
	}

	&__navLink {
		@include xio-link;
	}
}
