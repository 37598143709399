.xio-textButton {
	display: block;
	min-width: $textButtonMinWidth;
	position: relative;
	text-align: center;

	&::after {
		@include ease(opacity, $duration8);
		background-color: $colorBase;
		content: "";
		opacity: $buttonBackgroundOpacity;
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	&:hover {
		&::after {
			opacity: $buttonBackgroundHoverOpacity;
		}
	}

	&__text {
		color: $colorActive;
		display: block;
		padding: $base;
	}
}
