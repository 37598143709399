.xio-timelineAxis {
	@include center-block;
	display: flex;
	flex-flow: column;
	position: relative;
	width: $timelineWidth;
	font-family: $fontFamilyMono;
	@include xio-font(subtext);

	@include media($baseLayoutBreakpoint) {
		flex-flow: row;
		width: 100%;
	}

	&::before {
		background-color: $colorNotActiveBackground;
		content: "";
		width: 1px;
		margin-top: -$base4;
		position: absolute;
		left: 50%;
		height: calc(100% + #{$base4 * 2});
		@include media($baseLayoutBreakpoint) {
			background-color: $colorNotActiveBackground;
			content: "";
			height: 1px;
			margin-left: -1500px;
			position: absolute;
			margin-top: 0;
			top: $timelineHeight * 0.5;
			width: 4000px;
		}
	}

	&__element {
		border-top: 1px solid $colorNotActiveBackground;
		margin-left: $base05;
		margin-right: $base05;
		display: block;
		flex-grow: 1;
		position: relative;
		height: $timelineMobileHeight;

		@include media($baseLayoutBreakpoint) {
			margin-left: 0;
			margin-right: 0;
			height: $timelineHeight;
			border-top: none;
			border-left: 1px solid $colorNotActiveBackground;
		}

		&.xio-js-active {
			&::after {
				background-color: $colorBase;
				border-radius: 100%;
				border: 1px solid $colorActive;
				content: "";
				height: $base;
				left: 50%;
				position: absolute;
				top: 0;
				transform: translate(-50%, -50%);
				width: $base;
				@include media($baseLayoutBreakpoint) {
					left: 0;
					top: 50%;
				}
			}
		}

		&:last-of-type {
			@include media($baseLayoutBreakpoint) {
				border-bottom: none;
				border-right: 1px solid $colorNotActiveBackground;
			}
		}

		&--afterRelease {
			display: none;
			@include media($baseLayoutBreakpoint) {
				display: block;
			}
		}

		&--topLabel {
			margin-left: 0;
			margin-right: 0;
			border-top: 1px solid $colorBase;
			@include media($baseLayoutBreakpoint) {
				border-top: none;
				border-left: 1px solid $colorBase;
			}
		}

		&--highlight {
			&::before {
				@include media($baseLayoutBreakpoint) {
					background-color: $colorBase;
					content: "";
					height: 1px;
					width: 100%;
					position: absolute;
					top: 50%;
				}
			}
		}

		&--highlight2 {
			&::before {
				background-color: $colorActive;
				content: "";
				width: 2px;
				height: 100%;
				position: absolute;
				left: 50%;
				transform: translate(-50%, 0);
				top: 0;
				@include media($baseLayoutBreakpoint) {
					height: 2px;
					width: 100%;
					position: absolute;
					transform: translate(0, -50%);
					top: 50%;
					left: 0;
				}
			}
		}
	}

	&__bottomLabel {
		position: absolute;
		width: $base9;
		left: -$base9 - $base2;
		transform: translateY(-50%);
		color: $colorBaseNotActice;
		text-align: right;

		&--highlight {
			color: $colorBase;
		}

		&--highlight2 {
			color: $colorBase;
			font-weight: bold;
		}

		@include media($baseLayoutBreakpoint) {
			width: auto;
			text-align: left;
			bottom: -$base4;
			left: 0;
			white-space: nowrap;
			transform: translateX(-50%);
		}
	}

	&__topLabel {
		position: absolute;
		top: 0;
		right: -$base2;
		white-space: nowrap;
		transform: translate(100%, -50%);
		@include media($baseLayoutBreakpoint) {
			top: -$base4;
			left: 0;
			white-space: nowrap;
			transform: translateX(-50%);
		}
	}
}
