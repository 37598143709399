.xio-textPage {
	@include xio-container();
	padding-bottom: $base4;

	&__mainHeader {
		@include xio-font(h1);
		font-family: $fontFamilyLight;
	}

	&__logo {
		padding-top: $baseSmallMargin;
		padding-bottom: $baseSmallMargin;
	}

	&__head {
		@include xio-font(h3);
		font-family: $fontFamilyLight;
		padding-top: $base4;
		padding-bottom: $base2;
		max-width: $maxTextPageTextWidth;
	}

	&__copy {
		@include xio-font(base);
		max-width: $maxTextPageTextWidth;
		padding-bottom: $base2;
	}

	&__link {
		@include xio-link();
	}

	&__listElement {
		padding-bottom: $base;
		max-width: $maxTextPageTextWidth;
	}
}
