.xio-body {
	@include xio-font(base);
	color: $colorBase;
	font-family: $fontFamilyMain;
	height: 100%;
	hyphens: auto;
	overflow-x: hidden;
	overflow-y: scroll;
	position: relative;
}
