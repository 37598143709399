$htmlFontSize: 62.5%;

$fontSizes: (
	base: (
		font-size: pxToRem(14),
		line-height: 1.33,
		font-weight: normal,
		variants: (
			$baseLayoutBreakpoint: (font-size: pxToRem(16))
		)
	),
	h1: (
		font-size: pxToRem(24),
		line-height: 1.33,
		font-weight: normal,
		font-style: normal,
		variants: (
			$baseLayoutBreakpoint: (font-size: pxToRem(36))
		)
	),
	h2: (
		font-size: pxToRem(24),
		line-height: 1.33,
		font-weight: bold,
		font-style: normal,
		variants: (
			$baseLayoutBreakpoint: (font-size: pxToRem(36))
		)
	),
	h3: (
		font-size: pxToRem(18),
		line-height: 1.33,
		font-weight: normal,
		font-style: normal,
		variants: (
			$baseLayoutBreakpoint: (font-size: pxToRem(24))
		)
	),
	h: (
		font-size: pxToRem(16),
		line-height: 1.6,
		font-weight: bold
	),
	subtext: (
		font-size: pxToRem(12),
		line-height: 1.33,
		font-weight: normal,
		font-style: normal,
		variants: (
			$baseLayoutBreakpoint: (font-size: pxToRem(14))
		)
	)
);
