.xio-loader {
	background-color: $colorBgBase;
	display: block;
	height: 100vh;
	top: 0;
	left: 0;
	position: fixed;
	width: 100vw;
	z-index: 1000;

	body.no-js & {
		display: none;
	}

	body.xio-js-ready & {
		display: none;
	}
}
