
@mixin xio-container() {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	padding-left: $containerPaddingSmall;
	padding-right: $containerPaddingSmall;
	max-width: $containerMaxWidthLarge;

	@include media($baseLayoutBreakpoint) {
		padding-left: $containerPaddingLarge;
		padding-right: $containerPaddingLarge;
	}
}
