.xio-features {
	@include ease(opacity, $duration4);
	@include xio-container();
	margin-top: 0;
	opacity: 0;
	padding-top: 0;
	position: relative;
	visibility: hidden;

	@include media($baseLayoutBreakpoint) {
		margin-top: $baseLargeSize;
	}

	&.xio-js-ready {
		visibility: visible;
		opacity: 1;
	}

	&__headline {
		position: relative;
		z-index: 6;
	}

	&__image {
		width: 100%;
		height: auto;
	}

	&__imageImg {
		width: 100%;
	}

	&__items {
		margin-top: $baseXtraSmallSize;
		@include media($baseLayoutBreakpoint) {
			margin-top: $baseLargeSize;
		}
		z-index: 2;
	}

	&__item {
		margin-bottom: $baseSmallSize;
	}

	&__graphic {
		position: absolute;
		right: $containerPaddingLarge;
		top: $baseLargeSize + $baseSmallSize;
		max-width: 500px;
		display: none;

		@include media($baseLayoutBreakpoint) {
			display: block;
		}
	}

	&__videos {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	&__video {
		position: absolute;
		opacity: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
	}
}
