.xio-videoPopup {
	overflow: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 20;
	}

	&__video {
		iframe {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: auto;
			min-height: 100%;
		}

		video {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: auto;
			min-height: 100%;
		}
	}
}
