.xio-contactPerson {
	width: 100%;
	margin-bottom: $baseSmallMargin;

	@include media($smallLayoutBreakpoint) {
		margin-bottom: $base3;
	}

	@include media($baseLayoutBreakpoint) {
		width: dynamicColumnSize(4, 12, $baseGutter);
		//margin-right: dynamicColumnPush(1, 12, $baseGutter, true);
	}

	&__head {
		@include xio-font(h3);
		margin-bottom: $base;
	}

	&__image {
		width: 100%;
		margin-bottom: $base2;

		@include media($smallLayoutBreakpoint) {
			width: calc(50% - #{$base});
			display: inline-block;
			vertical-align: top;
		}

		@include media($baseLayoutBreakpoint) {
			width: 100%;
			display: block;
		}
	}

	&__imageImg {
		width: 100%;
	}


	&__info {
		@include xio-font(base);
		margin-bottom: $base;
		color: $colorBaseNotActice;
	}

	&__position {
		@include xio-font(base);
		margin-bottom: $base;
	}

	&__link {
		@include xio-link();
		font-family: $fontFamilyMain;
		display: block;
	}

	&__texts {
		@include media($smallLayoutBreakpoint) {
			display: inline-block;
			margin-left: $base2;
			vertical-align: top;
			width: calc(50% - #{$base2});
		}

		@include media($baseLayoutBreakpoint) {
			display: block;
			margin-left: 0;
			width: 100%;
		}
	}
}
