.xio-cookieInfo {
	@include xio-container();
	@include center(horizontal);
	bottom: 0;
	position: fixed;
	pointer-events: none;
	z-index: 9;

	&__container {
		background-color: $colorBgBase;
		margin-left: auto;
		margin-right: 0;
		max-width: $maxCookieInfoWidth;
		padding: 0;
		padding: $base2;
		pointer-events: all;
	}

	&__text {
		display: block;
		margin-bottom: $base2;
	}

	&__link {
		@include xio-link();
	}
}
