.xio-feature {
	@include ease(opacity, $duration4);
	cursor: pointer;
	display: flex;
	position: relative;
	z-index: 3;

	.xio-features.xio-js-active & {
		opacity: 0.1;

		&:hover {
			opacity: 0.7;
		}
	}


	&__button {
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: $base3;
		vertical-align: top;

		@include media($baseLayoutBreakpoint) {
			margin-right: $baseSmallMargin;
		}
	}

	&__texts {
		flex-grow: 1;
		vertical-align: top;
	}

	&__head {
		@include xio-font(h3);
		font-family: $fontFamilyLight;
		max-width: $maxFeatureHeadWidth;
	}

	&__copy {
		@include xio-font(base);
		font-family: $fontFamilyLight;
		margin-top: $base;
		max-width: $maxFeatureTextWidth;
	}
}
