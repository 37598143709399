.xio-formField {
	@include xio-font(base);
	background-color: $colorBgInput;
	border-radius: 0;
	border: none;
	color: $colorBase;
	height: $formBaseSize;
	padding-left: $base2;
	padding-right: $base2;
	width: 100%;
}
