.xio-header {
	min-height: 100vh;
	overflow: hidden;
	position: relative;
	width: 100%;

	&__bottomContent {
		bottom: 16vh;
		left: $containerPaddingSmall;
		opacity: 0;
		position: absolute;
		z-index: 5;

		@include media($baseLayoutBreakpoint) {
			bottom: 0;
			left: $containerPaddingLarge;
		}

		body.xio-js-ready & {
			opacity: 1;
			@include ease(opacity, $duration8 * 2, $easeOutQuad, $duration2 * 4);
		}
	}

	&__content {
		@include xio-container();
		position: relative;
		min-height: 100vh;

		//padding-bottom: $baseSmallMargin * 3;
	}

	&__tagline {
		@include xio-font(h1);
		filter: blur(50px);
		font-family: $fontFamilyLight;
		margin-left: $alignLeftHeaderToGrid;
		margin-top: $baseSmallMargin;
		max-width: $maxTaglineWidth;
		opacity: 0;
		padding-bottom: $baseSmallMargin;
		position: relative;
		z-index: 4;

		@include media($baseLayoutBreakpoint) {
			margin-top: $baseSmallMargin;
		}

		body.xio-js-ready & {
			opacity: 1;
			@include ease(filter opacity, $duration8 * 2.5, $easeOutQuad, $duration2);
			filter: blur(0);
		}
	}

	&__videoCta {
		display: block;
		margin-bottom: $baseSmallMargin * 3;
		opacity: 0;
		position: relative;

		body.xio-js-ready & {
			opacity: 1;
			@include ease(opacity, $duration8 * 2, $easeOutQuad, $duration2 * 8);
		}
	}

	&__videoCtaHead {
		@include xio-font(h3);
		color: $colorActive;
		padding-bottom: $base2;
	}

	&__logo {
		display: block;
		position: relative;
		padding-top: $baseSmallMargin;
		opacity: 0;
		z-index: 4;

		@include media($baseLayoutBreakpoint) {
			padding-top: $baseSmallMargin;
		}

		filter: blur(50px);

		body.xio-js-ready & {
			opacity: 1;
			@include ease(filter opacity, $duration8 * 2, $easeOutQuad, $duration2);
			filter: blur(0);
		}
	}

	&__logoSvg {
		display: block;
		width: $logoWidthSmall;
		height: $logoHeightSmall;

		@include media($baseLayoutBreakpoint) {
			width: $logoWidth;
			height: $logoHeight;
		}
	}

	&__video {
		height: 250%;
		left: -75%;
		position: absolute;
		top: 0;
		width: 250%;

		@include media($baseLayoutBreakpoint) {
			height: 100%;
			left: 0;
			width: 100%;
		}
	}
}
