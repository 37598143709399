.xio-modal {
	// @include storeValuesForJs((enableScrollMonitor: false))
	@include fadeOut($duration4);
	background-color: $colorBgBase;
	display: none;
	height: 100vh;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 20;

	&.xio-js-fixed {
		position: fixed;
	}


	&.xio-js-beforeActive {
		display: block;
		will-change: opacity, visibility;
	}

	&.xio-js-duringActive {
		@include fadeIn();
	}


	&__content {
		width: 100%;
		height: 100%;
		position: relative;
	}


	&__toggler {
		//height: $menuTogglerHeight;
		//position: absolute;
		//right: $containerPaddingLarge - $base4;
		//top: $base4;
		//width: $menuTogglerWidth;
	//	z-index: 200;
	}
}
