$colorBase: #ffffff;
$colorBgBase: #000000;

$colorActive: #e8cda3;

$colorBaseNotActice: rgba(255, 255, 255, 0.7);


$colorActiveBackground: rgba(255, 255, 255, 0.5);

$colorNotActiveBackground: rgba(255, 255, 255, 0.3);

$colorBgInput: rgba(255, 255, 255, 0.1);

$buttonBackgroundOpacity: 0.1;
$buttonBackgroundHoverOpacity: 0.19;
