$base: 1rem;
$base0: $base * 0;
$base1: $base * 1;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;
$base10: $base * 10;

$base05: $base / 2;
$base033: $base / 3;

$containerPaddingLarge: $base5;
$containerMaxWidthLarge: $base * 109.6 + $containerPaddingLarge * 2;

$containerPaddingSmall: $base3;

$baseLargeSize: $base * 11.5;
$baseSmallSize: $base * 5.6;
$baseXtraSmallSize: $baseSmallSize * 0.5;
$baseLargeMargin: $baseLargeSize;
$baseSmallMargin: $baseSmallSize;

$formBaseSize: $base * 6.4;

$logoWidth: $base * 15;
$logoHeight: $base * 6.2;
$logoWidthSmall: $base * 9.7;
$logoHeightSmall: $base * 4;

$baseGutter: $base2;

$iconSize: $base * 4.8;
$iconSizeSmall: $base * 3.6;

$maxTextWidth: $base * 47;

$maxTaglineWidth: $base * 47;

$alignLeftHeaderToGrid: -$base * 0.1;

$maxFeatureHeadWidth: $base * 45;
$maxFeatureTextWidth: $base * 35;

$maxCookieInfoWidth: $base10 * 5;

$footerTaglineYPosition: 20%;

$timelineHeight: $base5;
$timelineWidth: $base5;
$timelineMobileMargin: $base05;
$timelineMobileHeight: $base2;

$textButtonMinWidth: $base * 16;

$maxTextPageTextWidth: $base * 60;
