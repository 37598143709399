.xio-timeline {
	@include xio-container();

	margin-top: $baseSmallSize;
	@include media($baseLayoutBreakpoint) {
		margin-top: $baseLargeSize + $baseSmallSize;
	}

	&__content {
		margin-top: $baseXtraSmallSize + $base3;
		@include media($baseLayoutBreakpoint) {
			margin-top: $baseLargeSize + $base3;
		}
	}

	&__info {
		margin-top: $baseSmallSize + $base3;
		color: $colorBaseNotActice;
		max-width: $maxTextWidth;

		@include media($baseLayoutBreakpoint) {
			width: dynamicColumnSize(4, 12, $baseGutter);
		}
	}
}
