.xio-squareButton {
	display: block;
	height: $baseSmallSize;
	width: $baseSmallSize;

	@include media($baseLayoutBreakpoint) {
		height: $baseLargeSize;
		width: $baseLargeSize;
	}

	&--form {
		height: $formBaseSize;
		width: $formBaseSize;
		@include media($baseLayoutBreakpoint) {
			height: $formBaseSize;
			width: $formBaseSize;
		}
	}

	&__bg {
		@include ease(filter opacity, $duration8);
		background-color: $colorBase;
		border-radius: 100%;
		display: block;
		filter: blur(50px);
		height: 100%;
		left: 0;
		opacity: 0.18;
		position: absolute;
		top: 0;
		transform: translateZ(10px);
		width: 100%;

		.xio-squareButton--lightBg & {
			opacity: 0.1;
		}
	}

	&__bgBase {
		@include ease(opacity, $duration8);
		background-color: $colorBase;
		opacity: 0.1;
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;

		.xio-squareButton:hover & {
			opacity: 0.19;
		}
	}

	&__bgBlack {
		@include ease(filter opacity, $duration8);
		background-color: $colorBgBase;
		border-radius: 100%;
		display: block;
		filter: blur(30px);
		height: 100%;
		left: 0;
		opacity: 0.5;
		pointer-events: none;
		position: absolute;
		top: 0;
		transform: translateZ(23px);
		width: 100%;
		z-index: 2;

		.xio-squareButton--lightBg & {
			opacity: 0.2;
		}
	}

	&__content {
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	&__icon {
		@include center(both);
	}

	&__svg {
		@include ease(filter opacity path fill, $duration8, $easeOutQuad);
		backface-visibility: hidden;
		display: block;
		height: $iconSizeSmall;
		width: $iconSizeSmall;

		path {
		//	@include ease(fill, $duration8, $easeOutQuad);
		}

		svg {
			width: $iconSizeSmall;
			height: $iconSizeSmall;
		}

		@include media($baseLayoutBreakpoint) {
			height: 100%;
			width: 100%;

			svg {
				height: 100%;
				width: 100%;
			}
		}
	}
}
