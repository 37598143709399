.xio-contact {
	@include xio-container();
	margin-top: $baseSmallSize;
	@include media($baseLayoutBreakpoint) {
		margin-top: $baseLargeSize;
	}

	&__people {
		margin-top: $baseXtraSmallSize;

		@include media($baseLayoutBreakpoint) {
			margin-top: $baseLargeSize;
			@include dynamicColumns($baseGutter);
			flex-wrap: wrap;
		}
	}
}
